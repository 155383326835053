const test_prod = {
	utilityButton: "https://verification.diro.live/?buttonid=O.IN-v6EsGi-BOkp&trackid=",
	//BANK BUTTON LINK
	// bankButton : "https://session.diro.io/server/#/client/?buttonid=O.c6257a91-48c1-48a7-9f0b-e7724f25cabf-eVsb&trackid=", //url by Dhruv 
	bankButton : " https://verification.diro.live/?buttonid=O.IN-v6EsGi-zHJT&trackid=",                       // url by geetu 
	// bankButton : "https://diro.io/verification?buttonid=O.IN-ZGIvIV-UGzJ&trackid=",
    // bankButton : "https://verification.diro.live/?buttonid=O.IN-v6EsGi-gIhX&trackid=",
	// feedbackUrl: "https://api2.diro.live/cbre/api/sendsupportemail",
	feedbackUrl: "https://api2.diro.live/cbre/api/sendsupportemail",
	// frameSource: "https://session.diro.live/ https://verification.diro.live/",

	//bank button full doc
	// bankButtonFullDoc: "https://verification.diro.live/?buttonid=O.IN-AkxO0n-Rysn&trackid=",

	dirostatus: "https://stage2.diro.live/Zuul-1.0/organization-2.0/dirostatus",

	staticUtilityButtonId: "O.IN-v6EsGi-BOkp",

	staticBankOnlyButtonId: "O.IN-v6EsGi-zHJT",
	sentryDSN: "https://d6ec853bcb00dc9a1c5524f2bc3eddef@o1042621.ingest.us.sentry.io/4506869656059904",
	environment: "test_prod",


};

const prod = {
	utilityButton:"https://verification.diro.io/?buttonid=O.IN-3mEyEr-IcLC&trackid=",
	bankButton: "https://verification.diro.io/?buttonid=O.IN-3mEyEr-EVvy&trackid=",
	//bank button full doc
	// bankButtonFullDoc: "https://session.diro.io/server/#/client/?buttonid=O.c6257a91-48c1-48a7-9f0b-e7724f25cabf-8ZeY&trackid=",
	feedbackUrl: "https://api.diro.io/cbre/api/sendsupportemail",
	dirostatus: "https://prod.dirolabs.com/Zuul-1.0/organization-2.0/dirostatus",
	// feedbackUrl: "https://api2.diro.live/cbre/api/sendsupportemail",
	sentryDSN: "https://00d39e96232bd60dabfa651e54a6f7a9@o305199.ingest.us.sentry.io/4506874455851008",
	staticUtilityButtonId:"O.IN-3mEyEr-IcLC",
	staticBankOnlyButtonId:"O.IN-3mEyEr-EVvy",
	// staticBankOnlyButtonId: "O.c6257a91-48c1-48a7-9f0b-e7724f25cabf-1U9M",
	// staticBankFullButtonId: "O.c6257a91-48c1-48a7-9f0b-e7724f25cabf-8ZeY",

	environment: "prod",
};
export { test_prod };
export { prod as env };
