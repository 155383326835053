import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../App.css";

const LeftSideBar = () => {
  return (
    <Row
      className="h-adj ps-lg-4"
      style={{ height: "85vh", maxWidth: "410px" }}
    >
      <Col>
        <div>
          <p className="fs-5" style={{ fontWeight: "500" }}>
          Secure Document Verification
          </p>
          <div className="fs-6" style={{ textAlign: "justify" }}>
            <p className="text-left">
              {/* <span style={{ textAlign: "justify" }}>
                For &nbsp; prompt &nbsp; and &nbsp; accurate &nbsp; validation
                &nbsp; of &nbsp; your{" "}
              </span>{" "}
              address and/or bank details, initiate your secure verification by
              clicking the button on the right. */}
              To reduce the risk of fraud and ensure the timely and accurate payment of invoices,
               you are required to verify your bank account and proof of address.
            </p>
            <p className="text-left">
              <span style={{ textAlign: "justify" }}>
                {" "}
                Abillio &nbsp; partners &nbsp; with &nbsp;{" "}
                <a href="https://diro.io/" target="_blank" className="anchor">
                  DIRO
                </a>
                {"  "} &nbsp; for &nbsp; this{" "}
              </span>{" "}
              process, the leading provider of verification
              solutions trusted by F500 and tier-one banks.&nbsp;Visit DIRO <a
                href="https://trust.diro.io/"
                className="anchor"
                target="_blank"
              >
                 Trust Center.
              </a>
            </p>
            {/* <p className="text-left">
              Watch our tutorial to understand the process for{" "}
              <a
                href="https://diro.io/products/utility-bill-verification/"
                target="_blank"
                className="black-anchor"
              >
                {" "}
                address{" "}
              </a>{" "}
              and{" "}
              <a
                href="https://diro.io/products/bank-account-verification/"
                target="_blank"
                className="black-anchor"
              >
                bank
              </a>{" "}
              verifications.
            </p> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LeftSideBar;
