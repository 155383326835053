import React, { useState , useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../context";
import { env as environment } from "../controllers/environment";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const { setSupportPage,supportPage } = useContext(Context);
  const navigate = useNavigate();
  const accObj = {
    acc1: true,
    acc2: true,
    acc3: true,
    acc4: true,
  };
  const [accordion, setAccordion] = useState(accObj);
  const [input, setInput] = useState();
  const [supportEmail, setSupportEmail] = useState();
  const [radioSelection, setRadioSelection] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [radioErr, setRadioErr] = useState(false);
  const [inputErr, setInputErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  const handleToggleBody = (id) => {
    let accBoolean = accordion[id];
    setAccordion(accObj);
    setAccordion((prev) => {
      return { ...prev, [id]: !accBoolean };
    });
  };

  useEffect(() => {
    if (window.innerWidth > 768 && supportPage) {
      document.body.classList.add("no-scroll");
    } else {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (window.innerWidth > 768 && supportPage) {
        document.body.classList.remove("no-scroll");
      } else {
        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = 'auto';
      }
    };
  }, []);

  const handleSupportValidation = () => {
    if (!supportEmail) {
      setEmailErr(true);
      setTimeout(() => {
        setEmailErr(false);
      }, 5000);
      return false;
    }
    if (supportEmail) {
      const emailRegex = /\S+@\S+\.\S+/;
      // console.log("emailValidation", emailRegex.test(replytoemail));
      if (!supportEmail.match(emailRegex)) {
        setEmailErr(true);
        setTimeout(() => {
          setEmailErr(false);
        }, 5000);
        return false;
      }
    }

    if (radioSelection === "Other issue" && !input) {
      setInputErr(true);
      setTimeout(() => {
        setInputErr(false);
      }, 5000);
      return false;
    }
    if (!radioSelection) {
      setRadioErr(true);
      setTimeout(() => {
        setRadioErr(false);
      }, 5000);
      return false;
    }

    // if (radioSelection && supportEmail && radioSelection !== "Other issue" && input) return true;
    else return true;
  };

  const submitForm = async () => {
    // console.log(radioSelection);
    // console.log(input);
    const formdata = {
      email: supportEmail,
      selectedIssue: radioSelection,
      // comment: input ? input + "; " + "RadioSelection : " + radioSelection : "" + "" + "RadioSelection:" + radioSelection,
      comment: input ? input : `RadioSelection: ${radioSelection}`,
      source: "Abillio",
      selection: radioSelection,
      sessionId: "",
    };
    try {
      if (handleSupportValidation()) {
        setSpinner(true);
        await axios.post(environment.feedbackUrl, formdata).then(() => {
          setFormSubmitted(true);
          setSpinner(false);
        });
      }
    } catch (error) {
      // setValidationErr(true);
    }
  };
  const onRadioSelect = (e) => {
    setRadioSelection(e.target.value);
  };
  const handleInput = (e) => {
    setInput(e.target.value);
  };
  const handleEmail = (e) => {
    setSupportEmail(e.target.value);
  };
  const handleCloseThankyou = () => {
    setFormSubmitted(false);
    setSupportPage(false);
    navigate("/");
  };
  const handleCloseModal = () => {
    setSupportPage(false);
    navigate("/");
  };
  return (
    <>
      <div className="bg-blur">
        <div className="support-container">
          {!formSubmitted ? (
            <div>
              <i className="close2" onClick={handleCloseModal}></i>
              <h4>Get support</h4>
              <div className="accordion-container">
                <div id="accordionFlushExample">
                  <div className="accordion-item2 accItem">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className={`accordion-button2 `}
                        type="button"
                        onClick={() => handleToggleBody("acc1")}
                      >
                        Can't find my website
                        <i
                          className={`arrow ${accordion.acc1 ? "down" : "up"} `}
                        ></i>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className={`accordion-collapse${
                        accordion.acc1 ? " collapse-box" : ""
                      }`}
                    >
                      <div className="accordion-body2">
                        <ul
                          style={{
                            listStyle: "none",
                            padding: 0,
                            width: "100%",
                          }}
                        >
                          <li className="shadow-box">
                            <label
                              htmlFor="r1"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "266px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="radio"
                                value="Website is not loading"
                                id="r1"
                                className="exit-screen-input"
                                name="radio1"
                                onChange={onRadioSelect}
                              />
                              It's not loading
                            </label>
                          </li>
                          <li className="shadow-box">
                            <label
                              htmlFor="r2"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "266px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="radio"
                                value="Cannot find the website"
                                id="r2"
                                className="exit-screen-input"
                                name="radio1"
                                onChange={onRadioSelect}
                              />
                              Can't find it
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item2 accItem">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className={`accordion-button2 `}
                        type="button"
                        onClick={() => handleToggleBody("acc2")}
                      >
                        Can't login
                        <i
                          className={`arrow ${accordion.acc2 ? "down" : "up"} `}
                        ></i>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className={`accordion-collapse${
                        accordion.acc2 ? " collapse-box" : ""
                      }`}
                    >
                      <div className="accordion-body2">
                        <ul
                          style={{
                            listStyle: "none",
                            padding: 0,
                            width: "100%",
                          }}
                        >
                          <li className="shadow-box">
                            <label
                              htmlFor="r3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "266px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="radio"
                                value="forgot my password"
                                id="r3"
                                className="exit-screen-input"
                                name="radio1"
                                onChange={onRadioSelect}
                              />
                              Forgot my password
                            </label>
                          </li>
                          <li className="shadow-box">
                            <label
                              htmlFor="r4"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "266px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="radio"
                                value="Trouble typing password"
                                id="r4"
                                className="exit-screen-input"
                                name="radio1"
                                onChange={onRadioSelect}
                              />
                              Trouble typing password
                            </label>
                          </li>
                          <li className="shadow-box">
                            <label
                              htmlFor="r5"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "266px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="radio"
                                value="I have privacy concerns"
                                id="r5"
                                className="exit-screen-input"
                                name="radio1"
                                onChange={onRadioSelect}
                              />
                              I have privacy concerns
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item2 accItem">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className={`accordion-button2 `}
                        type="button"
                        onClick={() => handleToggleBody("acc3")}
                      >
                        Can't download
                        <i
                          className={`arrow ${accordion.acc3 ? "down" : "up"} `}
                        ></i>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className={`accordion-collapse${
                        accordion.acc3 ? " collapse-box" : ""
                      }`}
                    >
                      <div className="accordion-body2">
                        <ul
                          style={{
                            listStyle: "none",
                            padding: 0,
                            width: "100%",
                          }}
                        >
                          <li className="shadow-box">
                            <label
                              htmlFor="r6"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "266px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="radio"
                                value="Can't find the download page"
                                id="r6"
                                className="exit-screen-input"
                                name="radio1"
                                onChange={onRadioSelect}
                              />
                              Can't find the download page
                            </label>
                          </li>
                          <li className="shadow-box">
                            <label
                              htmlFor="r7"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "266px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="radio"
                                value="File is not downloading"
                                id="r7"
                                className="exit-screen-input"
                                name="radio1"
                                onChange={onRadioSelect}
                              />
                              File is not downloading
                            </label>
                          </li>
                          <li className="shadow-box">
                            <label
                              htmlFor="r8"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "266px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="radio"
                                value="Requested data was not available"
                                id="r8"
                                className="exit-screen-input"
                                name="radio1"
                                onChange={onRadioSelect}
                              />
                              Requested data was not available
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item2 accItem">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className={`accordion-button2 `} type="button">
                        <label
                          htmlFor="r9"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "266px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            type="radio"
                            value="Other issue"
                            id="r9"
                            className="exit-screen-input"
                            name="radio1"
                            onChange={onRadioSelect}
                          />
                          Other issue
                        </label>
                      </button>
                    </h2>
                  </div>
                </div>
                <div className="text-area-container">
                  <input
                    className="support-email"
                    type="text"
                    placeholder="Your email"
                    onChange={handleEmail}
                  />
                  <textarea
                    className="support-input"
                    name="text"
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Describe your issue"
                    onChange={handleInput}
                  ></textarea>

                  <div className="support_btn_wrapper">
                    {emailErr && (
                      <div className="support-error-msg">
                        Please enter a valid email
                      </div>
                    )}
                    {radioErr && (
                      <div className="support-error-msg">
                        Please select an option
                      </div>
                    )}
                    {inputErr && (
                      <div className="support-error-msg">
                        Please describe your issue
                      </div>
                    )}

                    <button
                      type="submit"
                      disabled={spinner ? true : false}
                      className="submit-button bg-dark-red"
                      onClick={submitForm}
                    >
                      {spinner ? (
                        <RotatingLines
                          strokeColor="white"
                          strokeWidth="4"
                          animationDuration="0.75"
                          width="25"
                          visible={true}
                        />
                      ) : (
                        " Submit"
                      )}
                    </button>
                    {/* <input type="submit" className="submit-button" value="Submit" onClick={submitForm} /> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="thankyou-screen">
							<i className="close2" onClick={handleCloseThankyou}></i>

							<p style={{ fontSize: "20px" }}>
								<b style={{ fontSize: "19px" }}> Thank you!</b> <br />
								<br />
								An email with your ticket ID <br />
								has been sent to you. <br />
								<br />
								Please check your spam folder.
							</p>
						</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Support;
