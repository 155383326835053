import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../context";

function NavScrollExample({ iframeRef }) {
  const location = useLocation();
  const { setMobileWarning } = useContext(Context);

  const handleLogoClick = () => {
    // console.log("handleLogoClick");
    // console.log(location.pathname);
    // ===== Post message to capture-process iframe to open the exit survey page =====
    if (location.pathname.includes("/verification")) {
      // console.log("condition passed");
      // console.log(iframeRef);
      iframeRef?.current?.children[0]?.contentWindow.postMessage(
        "Exit cbre session",
        "*"
      );
    }
    // window.location.href ="/"
  };
  const handleFocus = () => {
    // console.log("handleFocus");
    iframeRef?.current?.children[0].focus();
  };

  const handleClickOutside = (event) => {
    if (window.location.href.includes("/verification")) {
      event.preventDefault();
      handleFocus();
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  return (
    <Navbar expand="lg" className="nav-bg-orange  p-0" variant="dark" style={{backgroundColor:"#1334d1"}}>
      <Container fluid className="nav-bg-orange p-0 ">
        <Navbar.Brand
          onClick={handleLogoClick}
          className="col-lg-4 text-start ps-lg-5"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/abillio.png"}
            style={{ width: "120px",margin:'0.5rem',height:'45px'}}
            alt="logo not loaded"
            className="ps-lg-2 img-fluid cursor-pointer"
          />
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
        <Navbar.Collapse>
          <Nav style={{ maxHeight: "100px" }} className="ps-4">
            {/* <Nav.Link href="#" className="text-light opacity-75">
              HOME
            </Nav.Link>
            <Nav.Link href="#" className="text-light opacity-75">
              ABOUTUS
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
