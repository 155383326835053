import { useState, useRef } from "react";
import "./App.css";
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";
import { Context } from "./context";
import { useEffect } from "react";

function App() {
	const [data, setData] = useState({});
	const [inputEmail, setInputEmail] = useState();
	const [buttonSelection, setButtonSelection] = useState();
	const [buttonStatus, setButtonStatus] = useState();
	const [buttonIndex, setButtonIndex] = useState();
	const [extraBankButton, setExtraBankButton] = useState(false);
	const [resubmit, setResubmit] = useState(false);
	const [supportPage, setSupportPage] = useState(false);
	const [padding, setPadding] = useState("0px");
	const appRef = useRef();
	// const [fullscreen, setFullscreen] = useState(false);
	useEffect(() => {
		// This code is for check scroll width and hide scrolling in background.
		let winW = window.innerWidth;
		let winW2 = appRef.current.offsetWidth;
		if (supportPage) {
			window.document.body.classList.add("overflow-hide");
			setPadding(winW - winW2 + "px");
		} else {
			window.document.body.classList.remove("overflow-hide");
			setPadding("0px");
		}
		// console.log(winW, winW2, padding, "==================== body Width ================");
	}, [supportPage]);
	const getOs = () => {
		const os = ['Windows', 'Linux', 'Mac']; // add your OS values
		const winNav = window.navigator.userAgentData ? window.navigator.userAgentData.platform : window.navigator.appVersion;
		return os.find(v=>winNav.indexOf(v) >= 0);
	}

	const OS = getOs();

	const getBrowser = () => {
		const sUsrAg = navigator.userAgent;
		
		if (sUsrAg.indexOf("Firefox") > -1) {
			return "Firefox";
		} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
			return "Opera";
		} else if (sUsrAg.indexOf("Edge") > -1) {
			return "Edge";
		} else if (sUsrAg.indexOf("Chrome") > -1) {
			return "Chrome";
		} else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) {
			return "IE";
		} else if (sUsrAg.indexOf("Safari") > -1) {
			return "Safari";
		} else {
			return "no-browser";
		}
	};
	const browser = getBrowser();

	return (
		<div className={`${browser} ${OS} App`} ref={appRef} style={{ paddingRight: padding }}>
			<BrowserRouter>
				<Context.Provider
					value={{
						data,
						setData,
						inputEmail,
						setInputEmail,
						buttonSelection,
						setButtonSelection,
						buttonStatus,
						setButtonStatus,
						extraBankButton,
						setExtraBankButton,
						buttonIndex,
						setButtonIndex,
						resubmit,
						setResubmit,
						supportPage,
						setSupportPage,
						// fullscreen,
						// setFullscreen,
					}}
				>
					<Layout />
				</Context.Provider>
			</BrowserRouter>
			{/* <Iframe /> */}
		</div>
	);
}

export default App;
