import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftSideBar from "./LeftSideBar";
import Buttons from "./Buttons";
import InputField from "./InputField";
import RightSideBar from "./RightSideBar";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const HomePage = ({ className }) => {
  const [showAlert, setShowAlert] = useState(true);
  const [submitStatus, setStatus] = useState(
    sessionStorage.getItem("submitStatus") !== null
  );

  const iconStyle = {
    fontSize: "medium",
    cursor: "pointer",
  };


  useEffect(() => {
    // console.log("inside useEffect")
    const key = sessionStorage.getItem("key");
    // console.log("key", key);
    if (key === null) {
      // console.log("key is null");
      setShowAlert(true);

    } else {
      // console.log("key is not null");
      setShowAlert(false);
    }
    const timeoutId = setTimeout(() => {
      // console.log("Running after 5 seconds");
      // execute after 5 seconds
      sessionStorage.removeItem("key");
    }, 5000);
    // Cleanup function
    return () => { 
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    const storedReference = localStorage.getItem("reference");
    if (storedReference) {
      setReference(storedReference);
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      if (submitStatus === false) {
        document.body.style.overflow = "hidden";
      }
    }
  }, [showAlert]);


  function compareStringsWithoutQuotes(str1, str2) {
    // Remove single and double quotes from both strings
    const cleanStr1 = str1?.replace(/['"]/g, '');
    const cleanStr2 = str2?.replace(/['"]/g, '');
  
    // Compare the cleaned strings
    return cleanStr1 === cleanStr2;
  }
  

  useEffect(()=>{
    const value = sessionStorage.getItem("event");
    // console.log(value,"value")
    const compareValue = "Terminate session from guac";
    // console.log(compareStringsWithoutQuotes(value,compareValue),"result",value,compareValue)
    if(showAlert === false && submitStatus === false && compareValue){
      // console.log("false show alert ")
      document.body.style.overflow = "visible";
    }
  },[showAlert])

  useEffect(() => {
    submitStatus && setShowAlert(false);
  }, [submitStatus]);

  const [reference, setReference] = useState("");

  const handleClearLocalStorage = () => {
    localStorage.removeItem("reference");

    setReference(""); // Clear the state as well
  };

  const handleClose = () => {
    setShowAlert(false);
    document.body.style.overflow = "visible";
  };
  const alertStyle = {
    width: "340px",
    height: "180px",
    position: "absolute",
    padding: "40px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffa500",
    zIndex: showAlert ? 9999 : -1,
    borderColor: "#ffa500",
  };

  const closeBtnStyle = {
    position: "relative",
    top: "-140px",
    right: "-230px",
    cursor: "pointer",
    fontSize: "2rem",
    border: "0",
    backgroundColor: "transparent",
    color: "black",
  };
  const containerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    backdropFilter: "blur(5px)",
    zIndex: showAlert ? 9999 : -1,
  };
  return (
    <>
      {showAlert && (
        <div className="alert-container blur-mobile" style={containerStyle}>
          <div
            className="alert alert-warning al-dismiss alert-dismissible fade show"
            role="alert"
            style={alertStyle}
          >
            <span
              style={{
                textAlign: "justify",
                display: "inline-flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                fontWeight: 400,
                color: "black",
              }}
              className="fs-4"
            >
              Please use desktop for an optimized user experience.
            </span>

            <button
              type="button"
              style={closeBtnStyle}
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      )}
      <Container fluid className={`h-100 ${className}`}>
        {reference !== "" && (
          <p className="text-end">
            <FontAwesomeIcon
              icon={faX}
              style={iconStyle}
              onClick={handleClearLocalStorage}
            />
          </p>
        )}
        <Row className="pt-lg-5 pt-2 mt-0 mt-lg-4 add-scroll">
          <Col lg={4} md={12} className="ps-lg-5">
            <LeftSideBar />
          </Col>
          <Col lg={"3"} md={12}>
            {reference === "" ? (
              <div className="ps-lg-5 d-flex h-100 pt-3 ipad-air-align align-items-start  w-100 ps-5 pe-5 ps-md-2 ms-lg-4">
                <InputField setReference={setReference} reference={reference} />
              </div>
            ) : (
              <Buttons reference={reference} />
            )}
          </Col>
          <Col
            lg={1}
            className="d-none d-lg-block"
            style={{
              borderLeft: "2px solid black",
              height: "320px",
              transform: "translate(48%,0%)",
            }}
          />
          <Col lg={"4"} md={"12"}>
            <RightSideBar />
          </Col>
        </Row>
        {!showAlert && <Footer />}
      </Container>
    </>
  );
};

export default HomePage;
