import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import "../App.css";

const InputField = (props) => {
  const { setReference } = props;
  const [value, setValue] = useState("");
  const [emailValidation, setEmailValidation] = useState(true);

  const handleClick = () => {
    if (
			//eslint-disable-next-line
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				value
			)
		){
    localStorage.setItem("reference", value);
    setReference(value);
    // console.log("-----Input value2:----", value);
    }
    else{
      setEmailValidation(false);
			setTimeout(() => {
				setEmailValidation(true);
			}, 5000);
    }
  };
  const handleInputChange = (e) => {
    const lowercaseValue = e.target.value.toLowerCase();
    // console.log(lowercaseValue, "abillio email ");
    setValue(lowercaseValue);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };
  const adjustment = {
    height: "2rem",
    width: "15rem",
  };
  return (
    <div className="d-flex mt-lg-3 mt-3 mb-lg-0 mb-4" style={adjustment}>
      <InputGroup className="form">
        <Form.Control
          placeholder="Email"
          aria-label="Refernce ID"
          aria-describedby="referenceId"
          className="small-input border-2 border-end-0 border-dark-red p-2 fs-6"
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
        />
        <div
          style={{ border: "2px solid #1334d1" }}
          className="border-start-0 rounded-end-3"
        >
          <InputGroup.Text
            style={{ padding: "8px 12px", transform: "translate(4%,0)" }}
            className="btn btn-primary rounded-3  btn-md text-white bg-dark-red border-dark-red"
            id="referenceId"
            onClick={handleClick}
          >
            Go
          </InputGroup.Text>
          {!emailValidation ? <span className="invalid-email-text">Please enter a valid email</span> : ""}
        </div>
      </InputGroup>
    </div>
  );
};
export default InputField;
