import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";

const RightSideBar = () => {
  const [activeKey, setActiveKey] = useState(null);
  return (
    <div className={`d-flex justify-content-start align-self-center w-100 pt-4 pt-lg-0 pb-lg-0 pb-md-0 pb-5 mb-3 mb-lg-0 mb-md-0 }`}>
      <div
        className="overflow-scroll align-items-start scrollbar-area me-lg-5 pe-lg-5 pe-5 ipad-air-class w-100"
      >
        <div className="scrollbar-text ms-lg-3 ms-md-0 ms-sm-0">
          <p className="fs-5" style={{ fontWeight: "500" }}>
            <span className="fs-5"> Frequently asked questions </span>
          </p>
          <Accordion
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
            className="accord custom-accordion"
          >
            <Accordion.Item eventKey="0" className="border-0">
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={activeKey === "0" ? faMinus : faPlus}
                  className="text-dark-red custom-orange-icon me-2"
                />
                <span className="fs-6"> What information will be shared?</span>
              </Accordion.Header>
              <Accordion.Body className="pe-3 ">
                <ul>
                  <li>
                    Your bank statement will not be shared with Abillio. Only
                    selected fields as highlighted inside each session will be
                    shared. All other data will be purged.
                  </li>
                  <li>
                  Your utility bill will not be shared with Abillio. Only selected fields as highlighted inside each 
                  session will be shared. All other data will be purged.
                  </li>
                  <li>
                    Any sensitive information such as balance, or transactional
                    data, from your bank statement will never be shared with
                    Abillio.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="border-0">
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={activeKey === "1" ? faMinus : faPlus}
                  className="text-dark-red custom-orange-icon me-2"
                />
                <span className="fs-6">
                  How does my password remain private?{" "}
                </span>
              </Accordion.Header>
              <Accordion.Body className="pe-3 ">
                <ul>
                  <li>
                    The entire verification session provides end-to-end
                    encryption and remains private. In other words, you will not
                    share your password or login credentials with DIRO or Abillio.
                  </li>
                  <li>
                    Most banks use one-time passwords (OTP) with multi-layer
                    security.
                  </li>
                  <li>
                    Most banks use two-factor authentication (2FA) or
                    multi-factor authentication (MFA). The second factor is
                    sometimes offline, or on a separate device, such as a
                    smartphone for high security and to eliminate fraud.
                  </li>
                  <li>
                    Since you will not do a transaction, only verify bank
                    account ownership, your transaction password will never be
                    asked for in the verification session.
                  </li>
                  <li>
                    Visit DIRO to learn more about{" "}
                    <a
                      href="https://diro.io/user-privacy/"
                      target="blank"
                      rel="noopener"
                    >
                      user privacy.
                    </a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="border-0">
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={activeKey === "2" ? faMinus : faPlus}
                  className="text-dark-red custom-orange-icon me-2"
                />
                <span className="fs-6">Why should I trust DIRO? </span>
              </Accordion.Header>
              <Accordion.Body className="pe-3 ">
                <ul>
                  <li>
                    DIRO is trusted by F500 and Tier 1 global banks in consumer
                    and enterprise verifications across 195 countries. Its
                    solution is used to verify bank account ownership, proof of
                    address, and other documents to eliminate fraud, email
                    spoofing, and impersonation issues.
                  </li>
                  <li>
                    Visit DIRO to learn more about{" "}
                    <a
                      href="https://diro.io/products/business-verification/"
                      target="blank"
                      rel="noopener"
                    >
                      business verification.
                    </a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="border-0">
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={activeKey === "3" ? faMinus : faPlus}
                  className="text-dark-red custom-orange-icon me-2"
                />
                <span className="fs-6">
                  How DIRO creates a new global standard?
                </span>
              </Accordion.Header>
              <Accordion.Body className="pe-3 ">
                <ul>
                  <li>
                    DIRO's solution eliminates the possibility of document
                    fraud, email spoofing, and impersonation issues.
                  </li>
                  <li>
                    DIRO's ephemeral architecture of each session does not
                    permit, or make possible, any storage of passwords or other
                    login credentials. This makes the solution particularly
                    applicable to bank account verification and proof of address
                    in consumer and enterprise onboarding to eliminate fraud.
                  </li>
                  <li>
                    DIRO's verification of bank statements and utility bills
                    provides verification of selected source data directly from
                    the bank or utility company without the possibility of
                    tampering.
                  </li>
                  <li>
                    KYB (Know Your Business) is mostly a manual process with
                    uploads of simple PDFs which is open to tampering, fake, and
                    stolen data. This can lead to fraud and money laundering.
                    DIRO is solving this problem by creating a new global
                    standard for document verification in KYB and business verification.
                  </li>
                  <li>
                    DIRO participated in the UK's{" "}
                    <a
                      href="https://www.fca.org.uk/firms/innovation/regulatory-sandbox/accepted-firms"
                      target="blank"
                      rel="noopener"
                    >
                    FCA (Financial Conduct Authority) sandbox for KYC
                    </a>{" "}
                    in 2019 (cohort 5) with its bank account verification and
                    proof of address solution.
                  </li>
                  <li>
                  In addition to the UK's FCA, DIRO is in conversation with a number of regulators including
                  FATF, FinCEN, Malta Gaming Authority, Central Bank of India, Central Bank of Bahrain,
                  Central Bank of Malaysia, etc. The reason for these conversations is to educate and help
                  regulators to strengthen KYC, KYB, and AML requirements based on advancing technology.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="fs-6 pt-3">
          <span className="">Learn more about DIRO's </span>
            <a
              href="https://diro.io/products/utility-bill-verification/"
              target="_blank"
              className="anchor"
            >
              <span className="">address </span>
            </a>{" "}
            <span className="">and </span>
            <a
              href="https://diro.io/products/bank-account-verification/"
              target="_blank"
              className="anchor"
            >
              <span className="">bank </span>
            </a>{" "}
            <span className="d-inline-block"> verification solutions.</span>
          </div>
          <div className="py-2 pt-3 fs-6">
            <span className="pe-1 ">
              <a
                href="https://diro.io/term-condition/"
                className="anchor"
                target="_blank"
              >
                Terms of Use.
              </a>{" "}
            </span>
            <span>
              <a
                href="https://diro.io/privacy-policy/"
                className="anchor"
                target="_blank"
              >
                Privacy Policy.
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
