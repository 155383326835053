import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "../App.css";
import { Context } from "../context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Hourglass } from "react-loader-spinner";
import { env as environment } from "../controllers/environment";
import { Tooltip } from "react-tooltip";
import Support from "./Support";

const Buttons = (props) => {
  const { reference } = props;
  const navigate = useNavigate();
  const {
    inputEmail,
    setInputEmail,
    setButtonSelection,
    buttonStatus,
    setButtonStatus,
    setExtraBankButton,
    setButtonIndex,
    setResubmit,
    setSupportPage,
    supportPage,
  } = useContext(Context);
  // axios.defaults.headers.common["Authorization"] = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwcmFkeXVtbmFAZGlyby5pbyIsImFwaWtleSI6IjUwNmNhMjI3MzMzZjI0N2M4NzBmYTQ5OTE5NTgyMjQ4In0.ROTMhaKc7D_snWwO1wur7oAY73hyJfVKgLZgbUkCYrYHSfEBa0oMKo4RX3xL2zYiX1mXQEqD10HOPSjRVUhAIw;'
  const [emailValidation, setEmailValidation] = useState(true);
  const [showButton, setshowButton] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [spinner1, setSpinner1] = useState(false);

  const [utilityExecutionStatus, setUtilityExecutionStatus] = useState("");
  const [bankOnlyExecustionStatus, setBankOnlyExecustionStatus] = useState("");
  const [bankFullExecustionStatus, setBankFullExecustionStatus] = useState("");

  const bankVerification = () => {
    setButtonSelection("bank_verify");
    setResubmit(false);
    navigate(`/verification/${reference}`);
  };
  const handleSupport = () => {
    setSupportPage(true);
  };

  const addressVerification = () => {
    setButtonSelection("utility_verify");
    setResubmit(false);
    navigate(`/verification/${reference}`);
  };

  const bankVerificationFullDoc = () => {
    setButtonSelection("bank_verify_full_doc");
    setResubmit(false);
    navigate(`/verification/${reference}`);
  };
  const adjustment = {
    height: "3rem",
    fontWeight: 600,
    fontSize: "16px",
  };

  //utility

  useEffect(() => {
    // console.log("utility")
    const getUtilityStatus = async () => {
  //     // var json = {buttonid:'O.c6257a91-48c1-48a7-9f0b-e7724f25cabf-lRgz',trackid: localStorage.getItem('reference')};

      var json = {
        buttonid: environment.staticUtilityButtonId,
        trackid: localStorage.getItem("reference"),
      };
      try {
        const res = await axios.post(environment.dirostatus, json);

        const buttonStatus = res.data.message;

        if (buttonStatus === "JSON valid") {
          //done
          setUtilityExecutionStatus("completed");
        } else if (buttonStatus === "Done, in review." || sessionStorage.getItem("submitStatus")==='address') {
          setUtilityExecutionStatus("processing");
          setTimeout(()=>sessionStorage.clear(),4000);
        } else if (buttonStatus === "Invalid submission") {
  //         //done

          setUtilityExecutionStatus("rejected");
        } else if (buttonStatus === "fileobject is not present") {
          setUtilityExecutionStatus("notPresent");
        } else {
          setUtilityExecutionStatus("");
        }
      } catch {
        console.log("something went wrong in fetch data of utility button");
      }
    };
    getUtilityStatus();
  }, []);

  //bank only
  useEffect(() => {
    // console.log("bank only");
    const getBankStatus = async () => {
      var json = {
        buttonid: environment.staticBankOnlyButtonId,
        trackid: localStorage.getItem("reference"),
      };
      try {
        const res = await axios.post(environment.dirostatus, json);
        const buttonStatus = res.data.message;

        if (buttonStatus === "JSON valid") {
          //done
          setBankOnlyExecustionStatus("completed");
        } else if (
          buttonStatus === "Done, in review." ||
          sessionStorage.getItem("submitStatus") === "bank"
        ) {
          setBankOnlyExecustionStatus("processing");
          setTimeout(() => sessionStorage.clear(), 4000);
        } else if (buttonStatus === "Invalid submission") {
          //done

          setBankOnlyExecustionStatus("rejected");
        } else if (buttonStatus === "fileobject is not present") {
          setBankOnlyExecustionStatus("notPresent");
        } else {
          setBankOnlyExecustionStatus("");
        }
      } catch {
        console.log("something went wrong in fetch data of utility button");
      }
    };
    getBankStatus();
  }, []);

  //bank full
  // useEffect(() => {
  //   console.log("bank full")
  //   const getBankFullStatus = async () => {
  //     var json = {
  //       buttonid: environment.staticBankFullButtonId,
  //       trackid: localStorage.getItem("reference"),
  //     };
  //     try {
  //       const res = await axios.post(environment.dirostatus, json);
  //       const buttonStatus = res.data.message;

  //       if (buttonStatus === "JSON valid") {
  //         //done
  //         setBankFullExecustionStatus("completed");
  //       } else if (buttonStatus === "Done, in review."|| sessionStorage.getItem("submitStatus")==='bankfull') {
  //         setBankFullExecustionStatus("processing");
  //         setTimeout(()=>sessionStorage.clear(),4000);
  //       } else if (buttonStatus === "Invalid submission") {
  //         //done

  //         setBankFullExecustionStatus("rejected");
  //       } else if (buttonStatus === "fileobject is not present") {
  //         setBankFullExecustionStatus("notPresent");
  //       } else {
  //         setBankFullExecustionStatus("");
  //       }
  //     } catch {
  //       console.log("something went wrong in fetch data of utility button");
  //     }
  //   };
  //   getBankFullStatus();
  // }, []);

  return (
    <>
      {supportPage && <Support />}
      <div
        className="ms-lg-0 ms-md-0 ps-md-0 ms-4 ps-3 ps-lg-4 mt-3 mt-lg-0 d-flex flex-column align-items-start"
        style={{ height: "220px", width: "350px " }}
      >
        {/* <div>
         
          <p className="fs-5" style={{ fontWeight: "500" }}>
            Address Verification:
          </p>{" "}
        </div> */}
        <div className="align-items-center">
           <Button style={{fontWeight: "600"}}
            className={`adjustment rounded rounded-3 ${
              utilityExecutionStatus === "completed" ||
              utilityExecutionStatus === "processing"
                ? "btn-disabled-grey border-dark-grey"
                : "bg-dark-red border-dark-red"
            }`}
            onClick={() => addressVerification()}
            disabled={
              utilityExecutionStatus === "completed" ||
              utilityExecutionStatus === "processing"
            }
          >
            <span className="fs-6">Address Verification</span>
          </Button> 

          {utilityExecutionStatus === "completed" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/check-mark.png"}
                alt="Tick Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-completed"
                data-tooltip-content="The document is successfully submitted."
                data-tooltip-place="top"
              />
              <Tooltip id="my-tooltip-completed"  style={{zIndex:9999,backgroundColor:'black'}} />
            </span>
            
          )}

          {utilityExecutionStatus === "rejected" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/baddoc.png"}
                alt="Rejected Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-rejected"
                data-tooltip-content="The document was rejected, please resubmit."
                data-tooltip-place="top"
                style={{ width: "26px", height: "26px" }}
              />
              <Tooltip id="my-tooltip-rejected"  style={{zIndex:9999,backgroundColor:'black'}} />
            </span>
          )}

          {utilityExecutionStatus === "processing" && (
            <span
              className="ms-2 hover-cursor"
              data-tooltip-id="my-tooltip-utility"
              data-tooltip-content="The document is submitted and is under processing."
              data-tooltip-place="top"
            >
              <Hourglass
                visible={true}
                width="23"
                height="23"
                wrapperStyle={{}}
                ariaLabel="hourglass-loading"
                colors={["#6f5f38", "#6f5f38"]}
                wrapperClass=""
              />
              <Tooltip id="my-tooltip-utility"  style={{zIndex:9999,backgroundColor:'black'}} /> 
            </span>
          )}
        </div>

        {/* bank only button */}
        <div className="align-items-center">
          <Button
            style={{ fontWeight:"600"}}
            className={`adjustment mt-3 rounded rounded-3 ${
              bankOnlyExecustionStatus === "completed" ||
              bankOnlyExecustionStatus === "processing"
                ? "btn-disabled-grey border-dark-grey"
                : "bg-dark-red border-dark-red"
            }`}
            onClick={() => bankVerification()}
            disabled={
              bankOnlyExecustionStatus === "completed" ||
              bankOnlyExecustionStatus === "processing"
            }
          >
            <span className="fs-6 ">Bank Verification</span>
          </Button>
          {bankOnlyExecustionStatus === "completed" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/check-mark.png"}
                alt="Tick Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-bank-completed"
                data-tooltip-content="The document is successfully submitted."
                data-tooltip-place="top"
              />
              <Tooltip id="my-tooltip-bank-completed"  style={{zIndex:9999,backgroundColor:'black'}} />
            </span>
          )}

          {bankOnlyExecustionStatus === "rejected" && (
            <span className="ms-2 hover-cursor">
              <img
                src={process.env.PUBLIC_URL + "/assets/baddoc.png"}
                alt="Rejected Icon"
                style={{ width: "26px", height: "26px", marginTop: "15px" }}
                data-tooltip-id="my-tooltip-bank-rejected"
                data-tooltip-content="The document was rejected, please resubmit."
                data-tooltip-place="top"
              />
              <Tooltip id="my-tooltip-bank-rejected"  style={{zIndex:9999,backgroundColor:'black'}} />
            </span>
          )}

          {bankOnlyExecustionStatus === "processing" && (
            <span
              className="ms-2 hover-cursor"
              data-tooltip-id="my-tooltip-html-prop"
              data-tooltip-place="top"
            >
              <Hourglass
                visible={true}
                width="23"
                height="23"
                wrapperStyle={{}}
                ariaLabel="hourglass-loading"
                colors={["#6f5f38", "#6f5f38"]}
                wrapperClass=""
              />
              <Tooltip
              style={{zIndex:9999,backgroundColor:'black'}}
              id="my-tooltip-html-prop"
              html="The document is submitted and is under processing."
              />
            </span>
          )}

          <div className="main-container2">
          <div className="get_supp_btn_wrap">
            {/* {buttonStatus?.map((item, index) => {
									if (index === 0) {
										return (
											(item?.status?.utility_verify === "completed" || item?.status?.bank_verify === "completed") && (
												<><button style={{ marginLeft: "-1px" }} className="support_button" onClick={handleFresh}>
													{spinner2 ? (
														<RotatingLines strokeColor="rgb(0, 106, 77)" strokeWidth="4" animationDuration="0.75" width="25" visible={true} />
													) : (
														"Start fresh"
													)}
												</button><div style={{ width: "23px", display: "inline-block" }}>&nbsp;</div></>
											)
										);
									}
								})} */}
            <button className="support_button adjustment" onClick={handleSupport} style={adjustment}>
              Get support
            </button>
            {buttonStatus?.map((item, index) => {
              if (index === 0) {
                return (
                  (item?.status?.utility_verify === "completed" ||
                    item?.status?.bank_verify === "completed") && (
                    <div style={{ width: "23px", display: "inline-block" }}>
                      &nbsp;
                    </div>
                  )
                );
              }
            })}
          </div>
          {/* <div style={{ width: "62px" }}>&nbsp;</div> */}
          {/* <div className="get_supp_btn_space">&nbsp;</div> */}
        </div>
        </div>


      
        


        {/* <div className="main-container2"> */}
          {/* <div className="get_supp_btn_wrap"> */}
            {/* {buttonStatus?.map((item, index) => {
									if (index === 0) {
										return (
											(item?.status?.utility_verify === "completed" || item?.status?.bank_verify === "completed") && (
												<><button style={{ marginLeft: "-1px" }} className="support_button" onClick={handleFresh}>
													{spinner2 ? (
														<RotatingLines strokeColor="rgb(0, 106, 77)" strokeWidth="4" animationDuration="0.75" width="25" visible={true} />
													) : (
														"Start fresh"
													)}
												</button><div style={{ width: "23px", display: "inline-block" }}>&nbsp;</div></>
											)
										);
									}
								})} */}
            {/* <button className="support_button" onClick={handleSupport}>
              Get support
            </button>
            {buttonStatus?.map((item, index) => {
              if (index === 0) {
                return (
                  (item?.status?.utility_verify === "completed" ||
                    item?.status?.bank_verify === "completed") && (
                    <div style={{ width: "23px", display: "inline-block" }}>
                      &nbsp;
                    </div>
                  )
                );
              }
            })}
          </div> */}
          {/* <div style={{ width: "62px" }}>&nbsp;</div> */}
          {/* <div className="get_supp_btn_space">&nbsp;</div> */}
        {/* </div> */}

        {/* <div>
          <p className="fs-5 mt-4" style={{ fontWeight: "500" }}>
           
          </p>
          <Button
            style={adjustment}
            
            className={`rounded rounded-3 ${
             
                 "bg-white border-danger text-danger"
                //  "bg-dark-red border-dark-red"
            }`}
            
            onClick={() => bankVerificationFullDoc()}
            // disabled={
            //   bankFullExecustionStatus === "completed" ||
            //   bankFullExecustionStatus === "processing"
            // }
          >
            <span className="fs-6">Get Support</span>
          </Button>
        </div> */}

        {/* bank full statement */}

        {/* <div>
          <p className="fs-5 mt-4" style={{ fontWeight: "500" }}>
            Bank Verification:
          </p>
          <Button
            style={adjustment}
            className={`rounded rounded-3 ${
              bankFullExecustionStatus === "completed" ||
              bankFullExecustionStatus === "processing"
                ? "btn-disabled-grey border-dark-grey"
                : "bg-dark-red border-dark-red"
            }`}
            onClick={() => bankVerificationFullDoc()}
            disabled={
              bankFullExecustionStatus === "completed" ||
              bankFullExecustionStatus === "processing"
            }
          >
            <span className="fs-6">Bank statement (full document) </span>
          </Button>

          {bankFullExecustionStatus === "completed" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/check-mark.png"}
                alt="Tick Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-full-completed"
                data-tooltip-content=" Submission completed "
                data-tooltip-place="top"
                />
            <Tooltip id="my-tooltip-full-completed" />
            </span>
          )}

          {bankFullExecustionStatus === "rejected" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/baddoc.png"}
                alt="Rejected Icon"
                style={{ width: "26px", height: "26px"}}
                className="hover-cursor"
                data-tooltip-id="my-tooltip-full-rejected"
                data-tooltip-content="Invalid submission "
                data-tooltip-place="top"
              />
            <Tooltip id="my-tooltip-full-rejected" />
            </span>
          )}

          {bankFullExecustionStatus === "processing" && (
            <span
              className="ms-2 hover-cursor"
              data-tooltip-id="my-tooltip-full-processing"
              data-tooltip-content="Processing "
              data-tooltip-place="top"
            >
              <Hourglass
                visible={true}
                width="23"
                height="23"
                wrapperStyle={{}}
                ariaLabel="hourglass-loading"
                colors={["#6f5f38", "#6f5f38"]}
                wrapperClass=""
              />
            <Tooltip id="my-tooltip-full-processing" />
            </span>
          )}
        </div> */}

        {/* <div>
        <Button  style={adjustment} className="m-auto border-dark-yellow rounded rounded-3 bg-light text-dark-yellow  border-2"
        onClick={()=>handleGetSupport()}>
          <span className="fs-6">Get Support</span>
        </Button>
      </div> */}

        <div className="powered-by-diro">
          <p className=" d-flex justify-content-end align-items-center rounded rounded-3 w-100 mx-4">
            <span style={{ fontSize: "11px", fontWeight: 500 }}>
              Powered by&nbsp;
            </span>
            <img
              src={process.env.PUBLIC_URL + "/assets/powered-by-diro.png"}
              alt="Error while loading image"
              style={{ width: "28px" }}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default Buttons;
